




import Vue from 'vue';

export default Vue.extend({
  name: 'AppIcon',

  props: {
    src: {
      type: String,
      default: '@/assets/icons/icon-app-etc.svg',
    },
  },
});
