























































































































import Vue from 'vue';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';
import { UIRelationshipMapper } from '@/store/modules/ui/relationship';
import AppIcon from './AppIcon.vue';
import type { MemberEvent, Member } from '@/store/modules/ui/relationship';

const VoiceXPairingColors = ['princess-perfume', 'rajah', 'spray', 'portage'];

export default Vue.extend({
  name: 'UserStatus',

  components: {
    AppIcon,
  },

  props: {
    disableHighlight: {
      type: Boolean,
    },
    forceFrame: {
      type: Boolean,
      // プロジェクトデフォルト値を設定するために無効化
      // eslint-disable-next-line vue/no-boolean-default
      default: false,
    },
    frame: {
      type: Boolean,
      // プロジェクトデフォルト値を設定するために無効化
      // eslint-disable-next-line vue/no-boolean-default
      default: true,
    },
    user: {
      type: Object as Vue.PropType<Member>,
      required: true,
    },
  },

  data(): {
    appImageUrl: string;
    hasMeeting: boolean;
    hasSchedule: boolean;
    intervalID: number | undefined;
    voiceXPairingColorClass: string;
    showedHighlightVoiceXParing: boolean;
    showedUserNameTooltip: boolean;
    tooltip: {
      isVoiceX: boolean;
      schedule: MemberEvent[];
      text: string;
      voiceX: {
        elapsedTime: string;
        outbound: boolean;
        start: number;
      };
    };
  } {
    return {
      appImageUrl: '',
      hasMeeting: false,
      hasSchedule: false,
      intervalID: undefined,
      showedHighlightVoiceXParing: false,
      showedUserNameTooltip: false,
      tooltip: {
        isVoiceX: false,
        schedule: [],
        text: '',
        voiceX: {
          elapsedTime: '',
          outbound: false,
          start: 0,
        },
      },
      voiceXPairingColorClass: '',
    };
  },

  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    ...UICommonMapper.mapState(['popupUserId', 'showedMemberPopup', 'showedProfilePopup']),
    ...UIRelationshipMapper.mapState(['relationship']),

    highlight(): boolean {
      return this.user.userId === this.popupUserId;
    },

    isAttending(): boolean {
      if (this._.isUndefined(this.user)) {
        return false;
      }
      return this.user.attendanceFlag === 1;
    },

    showFrame(): boolean {
      if (this.forceFrame) {
        return true;
      }
      return this.frame && !this.highlight && !this._.isUndefined(this.user?.voiceXPairingColor);
    },

    showHighlight(): boolean {
      return (
        this.highlight &&
        (this.showedMemberPopup || this.showedProfilePopup) &&
        !this.disableHighlight
      );
    },

    userName(): string {
      const name = this._.get(this.user, 'userName', '');

      // TODO 実際には必ず名前は入っているので、このコードはリリース前に消す
      if (this._.isEmpty(name)) {
        return '不明';
      }

      return name;
    },

    userNameShorten(): string {
      const name = this._.get(this.user, 'userName', '');

      // TODO 実際には必ず名前は入っているので、このコードはリリース前に消す
      if (this._.isEmpty(name)) {
        return '不明';
      }

      return name.substring(0, 2);
    },
  },

  watch: {
    user: {
      handler() {
        this.setVoiceXPairingColor();
        this.updateTooltip();
        this.setAppImageUrl();
      },
      deep: true,
    },
  },

  created() {
    this.setVoiceXPairingColor();
    this.updateTooltip();
    this.setAppImageUrl();
  },

  methods: {
    ...UICommonMapper.mapActions([
      'setPopupUserId',
      'showMemberPopup',
      'setProfileUserId',
      'showProfilePopup',
    ]),

    hideNameTooltip() {
      this.showedUserNameTooltip = false;
    },

    onclickUser() {
      // プロフィールポップアップのクリックでは反応しない
      if (this.showedProfilePopup) {
        return;
      }

      // ポップアップに表示するユーザーIDをセット
      if (!this._.isUndefined(this.user.userId)) {
        this.setPopupUserId({ userId: this.user.userId });
        this.showMemberPopup();
      }
    },

    setAppImageUrl() {
      const images = require.context('@/assets/icons/', false, /\.svg$/);
      const imagePath = this._.get(this.user, 'currentApp.appImage', '');
      this.appImageUrl = this._.isEmpty(imagePath) ? images('./icon-app-etc.svg') : imagePath;
    },

    setVoiceXPairingColor() {
      if (this._.isUndefined(this.user?.voiceXPairingColor)) {
        this.voiceXPairingColorClass = '';
        this.showedHighlightVoiceXParing = false;
      } else {
        const colorIndex = this.user.voiceXPairingColor;
        const colorClass = this._.get(VoiceXPairingColors, colorIndex, '');
        this.voiceXPairingColorClass = `border-4px-${colorClass}`;
        this.showedHighlightVoiceXParing = true;
      }
    },

    showNameTooltip() {
      this.showedUserNameTooltip = true;
    },

    updateTooltip() {
      const self = this;

      if (this._.isUndefined(self.user)) {
        return;
      }

      const isVoiceX = self._.get(self.user, 'currentApp.voiceX', false);

      if (isVoiceX) {
        self.tooltip.isVoiceX = true;

        if (self.user.currentApp.outbound) {
          // memberIdに電話番号が入っている
          self.tooltip.text = self.user.currentApp.memberId;
        } else {
          const member = self._.find(self.relationship, {
            userId: self.user.currentApp.memberId,
          }) as Member;
          self.tooltip.text = member.userName;
        }

        self.intervalID = window.setInterval(() => {
          const dtFrom = self.$$dayjs.unix(self.user.currentApp.time);
          const dtTo = self.$$dayjs();
          const diff = dtTo.diff(dtFrom, 'second');
          const hh = self._.padStart(Math.floor(diff / 3600).toString(), 2, '0');
          const mm = self._.padStart(Math.floor((diff % 3600) / 60).toString(), 2, '0');
          const ss = self._.padStart((diff % 60).toString(), 2, '0');
          self.tooltip.voiceX.elapsedTime = `${hh}:${mm}:${ss}`;
        }, 1000);
      } else {
        self.tooltip.isVoiceX = false;
        self.tooltip.text = self._.get(self.user, 'currentApp.appName', '不明');

        if (self.intervalID !== undefined) {
          clearInterval(self.intervalID);
          self.intervalID = undefined;
        }
      }

      self.hasSchedule = self.user?.eventList?.length > 0;
      self.tooltip.schedule = self.user.eventList;

      // Googleカレンダー関連の処理
      self.hasMeeting = self._.some(
        self.tooltip.schedule,
        (meeting) => !self._.isEmpty(meeting.hangoutLink)
      );
    },
  },
});
